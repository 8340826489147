const logotext = 'DEVELOPER';
const meta = {
  title: 'Ram Kumar',
  description:
    'I’m Ram Kumar FrontEnd Developer _ Full stack devloper,currently working in Gurgaon, India',
};

const introdata = {
  title: 'I’m Ram Kumar',
  animated: {
    first: 'I love coding',
    second: 'I develop sophisticated websites',
    third: 'I prioritize code quality',
  },
  description:
    'Highly skilled Senior Frontend Developer with extensive experience in ReactJS, my expertise in Vanilla JavaScript, TypeScript, HTML, and CSS. Committed to delivering high-quality code and staying updated with the latest front-end development trends.',
  your_img_url: '/public/ram_pic.png',
};

const dataabout = {
  title: 'A bit about myself',
  aboutme:
    'Highly skilled Senior Frontend Developer with extensive experience in ReactJS seeking a challenging position to utilize my expertise in Vanilla JavaScript, TypeScript, HTML, and CSS. Committed to delivering high-quality code and staying updated with the latest front-end development trends.',
};
const worktimeline = [
  // {
  //       jobtitle: "Designer of week",
  //       where: "YAdfi",
  //       date: "2020",
  //   },
  //   {
  //       jobtitle: "Designer of week",
  //       where: "Jamalya",
  //       date: "2019",
  //   },
  //   {
  //       jobtitle: "Designer of week",
  //       where: "ALquds",
  //       date: "2019",
  //   },
];

const skills = [
  {
    name: 'ReactJS',
    value: 90,
  },
  {
    name: 'Next.js',
    value: 85,
  },
  {
    name: 'TypeScript',
    value: 88,
  },
  {
    name: 'Javascript',
    value: 89,
  },
  {
    name: 'HTML',
    value: 95,
  },
  {
    name: 'CSS',
    value: 96,
  },
];

const services = [
  {
    title: 'Frontend Development',
    description:
      'Leverage my expertise in ReactJS, JavaScript, HTML, CSS, and TypeScript to develop highly interactive and responsive web applications. Deliver seamless user experiences and ensure efficient and clean code implementation.',
  },
  {
    title: 'Dashboard Development',
    description:
      'Create custom dashboards, Design intuitive user interfaces and implement Frontend functionalities using Angular.',
  },
];

const contactConfig = {
  YOUR_EMAIL: 'Ramssss467@gmail.com',
  YOUR_FONE: '+91 (740)468-3168',
  description:
    "I'm delighted that you're interested in reaching out to me. Whether you have a question, a project idea, or just want to connect, I'm here to listen. Please feel free to fill out the contact form with your details and message, and I'll make sure to get back to you as soon as possible. Your input is important to me, and I look forward to the opportunity to collaborate and assist you with your needs.",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: '',
  YOUR_TEMPLATE_ID: '',
  YOUR_USER_ID: '',
};

const socialprofils = {
  github: 'https://github.com/RamKumarss',
  // facebook: "https://facebook.com",
  linkedin: 'https://www.linkedin.com/in/ram-sharma-973281210/',
  // twitter: "https://twitter.com",
};

export {
  meta,
  dataabout,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
