import React from 'react';
import './style.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { meta } from '../../content_option';
import airbnbImage from '../../assets/images/airbnb.webp';
import Distinct from '../../assets/images/distinct.webp';
import mario from '../../assets/images/mario.png';
import dgmarket from '../../assets/images/dg-market.png';
import car from '../../assets/images/car-race.png';
import disney from '../../assets/images/disney.webp';
import dg3 from '../../assets/images/dg3.png';
import movies from '../../assets/images/movies.png';
import gamavrs from '../../assets/images/gamavrs.webp';
import airind from '../../assets/images/airIndia.png';

const dataportfolio = [
  {
    img: disney,
    description: 'Disney plus clone.',
    link: 'https://disneyplusclone-1eb8e.web.app/',
  },
  {
    img: airind,
    description: 'Air India Dashboard',
    link: 'https://myai.airindia.com/',
  },
  {
    img: Distinct,
    description: 'Distinct - NFT Marketplace (Audio, Video, Image).',
    link: 'https://distinct.so/',
  },
  {
    img: mario,
    description: 'Mario - Online Game.',
    link: 'https://mario-nu-black.dev-ram.in',
  },
  {
    img: airbnbImage,
    description: 'Airbnb clone.',
    link: 'https://airbnb-clone-1adff.web.app/',
  },
  {
    img: dgmarket,
    description: 'DG Market - Tender/Biding',
    link: 'http://dg3-docker.dgmarket.com/',
  },
  {
    img: car,
    description: 'Car Game.',
    link: 'https://car-game-pi.dev-ram.in/',
  },

  {
    img: dg3,
    description: 'D3 Graphs',
    link: 'https://next-d3graph.dev-ram.in/',
  },

  {
    img: gamavrs,
    description: 'Gamavrs.',
    link: 'https://gamavrs.com/',
  },
];

export const Portfolio = () => {
  return (
    <HelmetProvider>
      <Container className='About-header'>
        <Helmet>
          <meta charSet='utf-8' />
          <title> Portfolio | {meta.title} </title>{' '}
          <meta name='description' content={meta.description} />
        </Helmet>
        <Row className='mb-5 mt-3 pt-md-3'>
          <Col lg='8'>
            <h1 className='display-4 mb-4'> Portfolio </h1>{' '}
            <hr className='t_border my-4 ml-0 text-left' />
          </Col>
        </Row>
        <div className='mb-5 po_items_ho'>
          {dataportfolio.map((data, i) => {
            return (
              <div key={i} className='po_item'>
                <img src={data.img} alt='' />
                <div className='content'>
                  <p>{data.description}</p>
                  <a href={data.link} target='__blank'>
                    view project
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </HelmetProvider>
  );
};
